/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Tag from '@/elements/Tag.js';
import DynamicIcon from '@/elements/DynamicIcon.js';
import DictionaryText from '@/elements/DictionaryText.js';

import { getDateString, getQueryVariable } from '@/utils/GenericUtils';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

import CmsFrontendControl from '@/services/CmsFrontendControl.js';

const listLength = 20;

export default function NewsLister(props) {
    const [news, setNews] = useState(null);

    useEffect(() => {
        if (props.data)
            setNews(props.data.value.contentList)
    }, [props.data])

    const loadModeNews = (_length) => {
        CmsFrontendControl.getList(props.data.value.key, news ? (news.length) : 0, _length ? _length : listLength).then(result => {
            setNews(news ? news.concat(result.contentList) : result.contentList);
        });
    }

    return (
        <>
            <VerticalNewsLister className='block sm:hidden' data={news} onListDemand={(l) => loadModeNews(l)} />
            <GridNewsLister className='hidden sm:flex' data={news} onListDemand={(l) => loadModeNews(l)} />
        </>
    )
}

const gridItemCount = 9;

function GridNewsLister(props) {
    const [blockCount, setBlockCount] = useState(1);

    const gap = 'gap-4';

    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const _block = getQueryVariable(location.search, 'lap')
        if (_block)
            setBlockCount(parseInt(_block))
    }, [])

    useEffect(() => {
        if (blockCount > 1) {
            history.push({
                pathname: location.pathname,
                search: '?lap=' + blockCount
            })
        }
    }, [blockCount])

    return (
        <div className={'w-full p-6 flex-col ' + gap + ' ' + (props.className || '')}>

            <div className={'w-full flex flex-col ' + gap}>
                {Array.apply(0, Array(blockCount)).map((item, index) => <GridNewsListerBlock key={index} data={props.data} from={index * gridItemCount} onListDemand={() => props.onListDemand()} />)}
            </div>

            <MoreButton onClick={() => setBlockCount(blockCount + 1)} />

        </div>
    );
}

function MoreButton(props) {
    return (
        <button className='bg-tv-purple hover:bg-tv-yellow w-full p-4 flex items-center justify-center transition group' onClick={() => props.onClick()} >
            <div className='flex flex-row text-white group-hover:text-black items-center justify-center'>
                <DynamicIcon className='text-xs dark:text-sm mb-0.5 mr-2' iconName='FaRegCalendar' />
                <div className='text-sm dark:text-base'><DictionaryText textKey={'earlier-news'} /></div>
                <DynamicIcon className='text-xxs dark:text-xs ml-1' iconName='FaChevronRight' />
            </div>
        </button>
    )
}

function GridNewsListerBlock(props) {
    const gap = 'gap-4';
    const itemClassName = 'shadow-xl';

    useEffect(() => {
        if (props.data) {
            const last = props.data[props.from + (gridItemCount - 1)];
            if (!last && props.data.length > 0)
                props.onListDemand();
        }
    }, [props])

    const getByIndex = (index) => {
        if (props.data && props.data[index + props.from])
            return props.data[index + props.from];
        else
            return null;
    }

    return (
        <>
            <NewsListerItem className={'w-full ' + itemClassName} data={getByIndex(0)} />

            <div className={'flex-1 grid grid-cols-2 ' + gap}>
                <NewsListerItem className={itemClassName} data={getByIndex(1)} />
                <NewsListerItem className={itemClassName} data={getByIndex(2)} />
            </div>

            <NewsListerItem className={'w-full ' + itemClassName} data={getByIndex(3)} />

            <div className={'flex-1 grid grid-cols-2 ' + gap}>
                <NewsListerItem className={itemClassName} data={getByIndex(4)} />
                <NewsListerItem className={itemClassName} data={getByIndex(5)} />
            </div>

            <NewsListerItem className={'w-full ' + itemClassName} data={getByIndex(6)} />

            <div className={'flex-1 grid grid-cols-2 ' + gap}>
                <NewsListerItem className={itemClassName} data={getByIndex(7)} />
                <NewsListerItem className={itemClassName} data={getByIndex(8)} />
            </div>
        </>
    );
}

function VerticalNewsLister(props) {
    const [blockCount, setBlockCount] = useState(1);

    const itemClassName = 'w-full bg-tv-gray-lightest mb-2';

    const getPreloadItems = (count) => {
        let items = [];
        for (let i = 0; i < count; i++) items.push(<NewsListerItem className={itemClassName} key={i} />);
        return items;
    }

    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const _block = getQueryVariable(location.search, 'lap')
        if (_block) {
            setBlockCount(parseInt(_block))
            props.onListDemand(parseInt(_block) * listLength)
        }
    }, [])

    useEffect(() => {
        if (blockCount > 1) {
            history.push({
                pathname: location.pathname,
                search: '?lap=' + blockCount
            })
        }
    }, [blockCount])

    return (
        <div className={'w-full ' + (props.className || '')}>
            <h1 className='p-4 font-bold text-3xl tracking-tighter text-tv-red dark:text-white'><DictionaryText textKey={'news'} /></h1>
            <div className='w-full flex flex-col'>
                {props.data ? props.data.map((item, index) => <NewsListerItem className={itemClassName} key={index} data={item} />) : getPreloadItems(6)}
            </div>
            <MoreButton onClick={() => {
                setBlockCount(blockCount + 1)
                props.onListDemand()
            }} />
        </div>
    );
}

function NewsListerItem(props) {
    let _data = {
        creationDate: props.data && props.data.publicCreationDate,
        author: (props.data && props.data.publicAuthor) && props.data.publicAuthor.name,
        photographer: getValueFromParameterMap(props.data, 'photographer'),
        tagList: getValueFromParameterMap(props.data, 'tagList'),
        image: (getValueFromParameterMap(props.data, 'coverImage') && !props.disableImage) && getValueFromParameterMap(props.data, 'coverImage').downloadUrl,
        title: getValueFromParameterMap(props.data, 'heading'),
        subTitle: getValueFromParameterMap(props.data, 'subHeading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
    }

    if (props.data)
        return (
            <div className={'dark:bg-tv-yellow ' + (props.className || '')}>

                <div className='flex flex-col'>

                    {/* <Link to={_data.url} className={'relative w-full flex items-end overflow-hidden transition-all group ' + (_data.image ? 'bg-tv-gray-light dark:bg-tv-gray-darkest h-48' : 'bg-tv-red-dark hover:bg-tv-purple')} >
                        <div className='absolute w-full h-full bg-cover bg-center transition-all filter group-hover:grayscale group-hover:blur-xxs dark:opacity-50' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                        <div className='z-10 display-inline text-lg dark:text-2xl font-bold text-white text-semibold m-4 transition-all bg-tv-red-dark group-hover:bg-tv-purple shadow-floating-title-tv-red group-hover:shadow-floating-title-tv-purple'>{_data.title}</div>
                    </Link> */}

                    <Link to={_data.url} className={'relative w-full flex items-end overflow-hidden transition-all group ' + (_data.image ? 'bg-tv-gray-light dark:bg-tv-gray-darkest' : 'bg-tv-red-dark hover:bg-tv-purple')} >
                        {_data.image && <svg className='w-full invisible' viewBox='0 0 16 9' />}
                        <div className='absolute w-full h-full bg-cover bg-center transition-all filter group-hover:grayscale group-hover:blur-xxs dark:opacity-50' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                        <div className={'z-10 display-inline text-lg dark:text-2xl font-bold text-white text-semibold m-4 transition-all bg-tv-red-dark group-hover:bg-tv-purple shadow-floating-title-tv-red group-hover:shadow-floating-title-tv-purple ' + (_data.image && 'absolute bottom-0')}>{_data.title}</div>
                    </Link>

                    <div className='p-4 flex flex-col gap-2 overflow-hidden'>

                        <div className='flex flex-row gap-1.5 border-b dark:border-tv-gray-darkest dark:border-opacity-30 pb-1.5 items-center'>

                            {_data.creationDate && <NewsListerItemMeta className='flex-shrink-0' icon='FaRegCalendar' value={getDateString(new Date(_data.creationDate))} />}

                            {(_data.author || _data.photographer) && <div className='h-3 border-l dark:border-tv-gray-darkest' />}

                            {_data.author && <NewsListerItemMeta className='flex-shrink-0' icon='FaUserAlt' value={_data.author} />}

                            {_data.photographer && <div className='h-3 border-l dark:border-tv-gray-darkest' />}

                            {_data.photographer && <NewsListerItemMeta icon='FaCamera' value={_data.photographer} />}

                        </div>

                        <div className='flex flex-col gap-1 md:gap-2'>
                            {/* {_data.subTitle && <div className='text-sm dark:text-base font-semibold dark:font-bold'>{_data.subTitle}</div>} */}
                            <div className='text-xs dark:text-sm md:text-sm md:dark:font-semibold' dangerouslySetInnerHTML={{ __html: _data.lead }} />
                        </div>

                        {(_data.tagList && _data.tagList.length > 0) && <div className='flex flex-row flex-wrap gap-1 pt-1'>
                            {_data.tagList.map((item, index) => <Tag key={index} data={item} />)}
                        </div>}

                    </div>

                </div>

            </div>
        )
    else
        return (<div className='bg-tv-gray-lightest w-full h-48' />)
}

function NewsListerItemMeta(props) {
    return (
        <div className={'text-xxs dark:text-xs md:text-xxs md:dark:text-sm font-thin flex flex-row items-center truncate ' + (props.className || '')}><DynamicIcon className={'mr-1.5'} iconName={props.icon} />{props.value}</div>
    )
}