import React from 'react';

import StickyHeader from '@/elements/StickyHeader';
import DynamicIcon from '@/elements/DynamicIcon.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';
import { getDateString } from '@/utils/GenericUtils';

export default function TransportedVehicles(props) {
    const _data = props.data.value.contentList;

    return (
        <div className={'w-full flex flex-col min-h-full ' + (props.className || '')}>

            <StickyHeader
                heading='Elszállított gépjárművek'
                content='A közterület-felügyelő a helyi közúton közterület-használati engedély nélkül tárolt járművet, amelyik közúti forgalomban csak hatósági engedéllyel vehetne részt, de azzal nem rendelkezik, elszállíthatja (jogalap: a közterület-felügyeletről szóló 1999. évi LXIII. törvény 20.§).
                <br><br>
                <strong>A jármű az elszállításkor ide kerül:</strong> 1151 Budapest, Szántóföld u. 7.<br>
<strong>Elszállítás költsége személyautónak a bírságon felül:</strong> 17 250 Ft<br>
<strong>Tárolási költség személyautónak:</strong> 1200 Ft/nap
<br><br>
Az elszállított autók után a közterület-felügyelet 24 órában hívható telefonszámán lehet érdeklődni: <a href="tel:+3612792470">+36&nbsp;1&nbsp;279&nbsp;2470</a><br>
E-mail: <a href="mailto:ugyelet.kozter@terezvaros.hu">ugyelet.kozter@terezvaros.hu</a>' />

            <div className='flex-1  p-4 sm:p-8 bg-fill-base z-10'>
                {(_data && _data.length > 0) ?
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-8'>{_data.map((item, index) => <VehicleItem key={index} data={item} />)}</div> : <div>Jelenleg nem található elszálított gépjármű az adatbázisban.</div>}
            </div>

        </div>
    )
}

function VehicleItem(props) {
    const _data = {
        date: getValueFromParameterMap(props.data, 'elszallitas-idopontja'),
        license: getValueFromParameterMap(props.data, 'rendszam'),
    };

    return (
        <div className='bg-tv-purple p-4 gap-4 text-white flex flex-col sm:flex-row items-center shadow-lg rounded'>

            <div className='sm:max-w-20 flex flex-col items-center text-center justify-center opacity-50'>
                <DynamicIcon className='text-6xl sm:text-5xl' iconName={'FaCar'} />
                <div className='text-xs sm:text-xxs uppercase'>{_data.license}</div>
            </div>

            <div className='flex-1 w-full flex flex-col gap-1 bg-white p-4 rounded bg-opacity-5'>
                <VehicleDataLine label='Rendszám' value={_data.license} uppercase />
                <VehicleDataLine label='Elszállítás időpontja' value={_data.date ? getDateString(new Date(_data.date)) : 'Nem ismert'} />
            </div>

        </div>
    )
}

function VehicleDataLine(props) {
    const value = (props.value.toUpperCase() === 'X' || props.value.toUpperCase() === 'RENDSZÁM NÉLKÜLI' || props.value.toUpperCase() === 'HATÓSÁGI JELZÉS NÉLKÜL' || props.value.toUpperCase() === 'HATÓSÁGI JELZÉS NÉLKÜLI GÉPJÁRMŰ') ? 'Nem ismert' : (props.uppercase ? props.value.toUpperCase() : props.value);
    return (
        <div className='text-xs flex flex-row gap-1 justify-center'>
            <div>{props.label + ': '}</div>
            <div className={'font-bold'}>{value}</div>
        </div>
    );
}